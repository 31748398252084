import * as React from 'react';
import styled from 'styled-components';
import { device } from '../../utils';

interface BurgerProps {
  showNav: () => void;
  showNavState: boolean;
}

interface BurgerState {
  showNav: boolean;
  prevScrollpos: any;
  visible: boolean;
}

// declare global {
//   interface Window {
//     pageYoffset: any;
//   }
// }

class BurgerIcon extends React.Component<BurgerProps, BurgerState> {
  constructor(props: BurgerProps) {
    super(props);
    this.state = {
      showNav: false,
      prevScrollpos: typeof window !== 'undefined' && window.pageYOffset ? window.pageYOffset : 0,
      visible: true,
    };
  }

  componentDidMount = () => {
    typeof window !== 'undefined' && window.addEventListener('scroll', this.handleScroll);
  };

  componentWillUnmount() {
    typeof window !== 'undefined' && window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const { prevScrollpos } = this.state;
    const currentScrollPos =
      typeof window !== 'undefined' && window.pageYOffset && window.pageYOffset;
    const visible = prevScrollpos >= currentScrollPos || prevScrollpos < 0;
    if (!this.props.showNavState) {
      this.setState({
        prevScrollpos: currentScrollPos,
        visible: visible,
      });
    }
  };

  render() {
    return (
      <BurgerContainer visible={this.state.visible} showNav={this.state.showNav}>
        <BurgerIconDiv showNav={this.props.showNavState} onClick={this.props.showNav}>
          <Bar />
          <Bar />
          <Bar />
        </BurgerIconDiv>
      </BurgerContainer>
    );
  }
}

interface ContainerProps {
  visible: boolean;
  showNav: boolean;
}

interface IconState {
  showNav: boolean;
}
const BurgerContainer = styled.div<ContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 10px;
  height: 40px;
  /* top: 50px; */
  top: ${props => (props.visible ? '50px' : '-30px')};
  width: 80px;
  z-index: 8;
  transition: top 300ms;
  @media ${device.mobileL} {
    left: 50px;
  }
`;

const Bar = styled.span`
  display: block;
  width: 3.8em;
  height: 0.2em;
  margin: 0.9em;
  border-right: 3.8em solid #fff;
  /* 	border-left: 0.6em solid #d6d6d6; */
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  /* -webkit-transition-property: -webkit-transform, margin, border-right-color, box-shadow;
  -moz-transition-property: -moz-transform, margin, border-right-color, box-shadow;
  transition-property: transform, margin, border-right-color, box-shadow; */
  -webkit-transition-duration: 600ms;
  -moz-transition-duration: 600ms;
  transition-duration: 600ms;
`;

const BurgerIconDiv = styled.div<IconState>`
  /* border: 1px solid white; */
  display: block;
  position: relative;
  width: 100%;
  margin: ${props => (props.showNav ? '-3.2em 0 0 0.7em' : '-2.2em auto 0 0.2em')};
  -webkit-transition: margin 300ms;
  -moz-transition: margin 300ms;
  transition: margin 300ms;
  cursor: pointer;
  ${Bar}:nth-of-type(1) {
    opacity: ${props => (props.showNav ? '0' : '1')};
    -webkit-transition: opacity 150ms;
    -moz-transition: opacity 150ms;
    transition: opacity 150ms;
  }
  ${Bar}:nth-of-type(2) {
    -webkit-transform: ${props =>
      props.showNav ? 'rotate(-137deg) translateY(-1.0em)' : 'rotate(-180deg)'};
    -moz-transform: ${props =>
      props.showNav ? 'rotate(-137deg) translateY(-1.0em)' : 'rotate(-180deg)'};
    -ms-transform: ${props =>
      props.showNav ? 'rotate(-137deg) translateY(-1.0em)' : 'rotate(-180deg)'};
    -o-transform: ${props =>
      props.showNav ? 'rotate(-137deg) translateY(-1.0em)' : 'rotate(-180deg)'};
    transform: ${props =>
      props.showNav ? 'rotate(-137deg) translateY(-1.0em)' : 'rotate(-180deg)'};
  }
  ${props =>
    props.showNav &&
    `
    ${Bar}:nth-of-type(3) {
		margin-left: 0.5em;
		-webkit-transform: rotate(-42deg) translateY(-0.4em);
		-moz-transform: rotate(-42deg) translateY(-0.4em);
		-ms-transform: rotate(-42deg) translateY(-0.4em);
		-o-transform: rotate(-42deg) translateY(-0.4em);
    transform: rotate(-42deg) translateY(-0.4em);
    }
  `}
  ${props =>
    props.showNav &&
    `
      :hover {
    ${Bar}:nth-of-type(2) {
      border-right-color: #b3dbe8;
      box-shadow: 0em 0 0.6em 0.1em rgba(47, 203, 252, 1);
    }
    ${Bar}:nth-of-type(3) {
      border-right-color: #b3dbe8;
      box-shadow: 0em 0 0.6em 0.1em rgba(47, 203, 252, 1);
    }
  }
  `}
`;

export default BurgerIcon;
