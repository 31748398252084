import { detect } from 'detect-browser';

const browser = detect();
export const browserName = browser ? browser.name : '';
export const OSName = browser ? browser.os : '';

const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tabletS: '750px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '1800px',
  desktopL: '2560px',
  mobileMHeight: '700px',
  mobileLHeight: '800px',
};

const orientation = {
  potrait: `(orientation: potrait)`,
  landscape: `(orientation: landscape)`,
};

export const device = {
  mobileS: `(max-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  mobileSHeight: `(min-height: ${size.mobileMHeight}) and (min-width: ${size.mobileS})`,
  mobileMHeight: `(min-width: ${size.mobileM}) and (min-height: ${size.mobileMHeight})`,
  mobileLHeight: `(min-width: ${size.mobileM}) and (min-height: ${size.mobileMHeight})`,
  tablet: `(min-width: ${size.tabletS})`,
  tabletSHeight: `(min-width: ${size.tabletS}) and (min-height: ${size.laptop})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopHeight: `(min-width: ${size.laptop}) + and (min-height: ${size.mobileLHeight})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktopL})`,
  mobileLandscapeL: `(min-width: ${size.mobileLHeight}) and (orientation: landscape)`,
  tabletSLandscape: `(min-width: ${size.laptop})and (orientation: landscape)`,
  ipadPro: `screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1366px) 
  and (-webkit-min-device-pixel-ratio: 2)`,
};

export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

// @media ${device.mobileS} {
// }
// @media ${device.mobileM} {
// }
// @media ${device.mobileMHeight} {
// }
// @media ${device.mobileLHeight} {
// }
// @media ${device.tabletSHeight} {
// }
// @media ${device.mobileL} {
// }
// @media ${device.laptop} {
// }
// @media ${device.laptopL} {
// }
// @media ${device.desktop} {
// }
// @media ${device.desktopL} {
// }

export const mobileSize = 'screen and (max-width: 768px)';
export const laptopSize = 'screen and (max-width: 992px)';
export const desktopSize = 'screen and (max-width: 1280px)';
export const smallMobileSize = 'screen and (max-width: 500px)';

export const HUBSPOT_API_KEY = '5090975';
export const HUBSPOT_API_FORM_GUI = '6db27be0-b233-4a49-aacb-ffe70a246e3d';
export const HUBSPOT_API_STAY_UPDATED_FORM = "59905ca2-6442-4183-9b5f-d29fad10bc04";

export const OFFICE_ADDRESS = '250 W 34th St. STE 203';
export const OFFICE_STATE_ZIP = 'New York, NY 10019';
