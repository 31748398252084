import * as React from 'react';
import styled from 'styled-components';
import BurgerIcon from './BurgerIcon';
import { Link } from 'gatsby';
// import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import { device } from '../../utils';

interface NavBarProps {}

interface NavBarState {
  showNav: boolean;
}

class NavBar extends React.Component<NavBarProps, NavBarState> {
  constructor(props: NavBarProps) {
    super(props);

    this.state = {
      showNav: false,
    };
  }

  showNav = () => {
    this.setState({
      showNav: !this.state.showNav,
    });
  };

  render() {
    return (
      <NavBarContainer>
        <NavButtonDiv>
          <BurgerIcon showNav={this.showNav} showNavState={this.state.showNav} />
          <SideNavDiv showNav={this.state.showNav}>
            <NavigationContainer>
              <StyledLink to="/" aria-label="rapptr">
                Home
              </StyledLink>

              <StyledLink to="/portfolio/movo" aria-label="portfolio">
                Portfolio
              </StyledLink>
              <StyledLink to="/academy" aria-label="academy">
                Academy
              </StyledLink>
            </NavigationContainer>
          </SideNavDiv>
        </NavButtonDiv>
      </NavBarContainer>
    );
  }
}

interface SideNavProps {
  showNav: boolean;
}

const NavBarContainer = styled.div`
  width: 100%;
  background-color: transparent;
  position: fixed;
  left: 0;
  height: 100px;
  /* top: ${(props) => (props.visible ? '0px' : '-100px')}; */
  display: flex;
  transition: 400ms ease-in-out;
  justify-content: flex-start;
  /* border-bottom: 2px solid #2fcbfc; */
  z-index: 6;
`;

const NavButtonDiv = styled.div`
  margin-top: 50px;
  margin-right: 20px;
`;

const SideNavDiv = styled.div<SideNavProps>`
  height: 100vh;
  width: ${(props) => (props.showNav ? '100vw' : '0')};
  max-width: 480px;
  position: absolute;
  z-index: 7;
  left: -1px;
  top: -1px;
  overflow-x: hidden;
  transition: 600ms;
  background-color: black;
  background-image: linear-gradient(134deg, #2fcbfc 16%, #26444e 105%);
  border-bottom: ${(props) => (props.showNav ? '2px solid #2fcbfc;' : '0px solid #2fcbfc;')};
`;

const NavigationContainer = styled.div`
  margin-top: 100px;
  width: 100%;
  height: calc(100% - 100px);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

const StyledLink = styled(Link)`
  font-family: regulator-nova;
  font-size: 24px;
  font-weight: 500;
  line-height: 2.52;
  letter-spacing: 5.6px;
  text-align: left;
  color: #f9f9fb;
  text-decoration: none;
  text-transform: uppercase;
  transition: color 0.5s;
  font-size: 32px;
  line-height: 68px;
  opacity: 1;
  :hover {
    color: #2fcbfc;
    text-decoration: none;
  }
`;

export default NavBar;
