import styled from 'styled-components';
import { theme } from '../../utils/theme';

interface Props {
  size?: number;
  color?: string;
}

export const NewAppText = styled.p<Props>`
  font-family: ${theme.PRIMARY_FONT};
  font-size: ${props => props.size ? props.size : 14}px;
  letter-spacing: 0.26px;
  color: ${props => props.color ? props.color : theme.WHITE_COLOR};
  text-align: center;
  margin: 0 0 1.5em 0;
  text-align: left;
`;
