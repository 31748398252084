import styled from 'styled-components';

export const AppText = styled.p`
  font-family: Nunito Sans;
  font-size: 14px;
  letter-spacing: 0.26px;
  color: #ffffff;
  text-align: center;
  margin: 0;
`;
