import { motion } from 'framer-motion';
import styled from 'styled-components';
import { theme } from '../../utils/theme';

export const NewAppButton = styled(motion.button)`
  font-family: ${theme.PRIMARY_FONT};
  font-size: 14px;
  letter-spacing: 0.26px;
  color: ${theme.WHITE_COLOR};
  text-align: center;
  padding: 0px 10px;
  height: 40px;
  background-color: transparent;
  margin: 0;
  border: 1px solid ${theme.WHITE_COLOR};
  box-sizing: border-box;
  border-radius: 7px;
  min-width: 146px;
  transition: all 150ms ease-in-out;
  :hover {
    scale: 1.05;
    background-color: ${theme.WHITE_COLOR};
    color: ${theme.MAIN_DARK_BLUE};
  }
  :active {
    scale: 0.95;
  }
`;
