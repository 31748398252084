import * as React from 'react';
import styled from 'styled-components';
import { device } from '../../utils';
import { ViewWork } from '../../assets';
import { theme } from '../../utils/theme';

export const RapptrButton = props => {
  return (
    <StyledViewWorkButton color={props.color} height={props.height} width={props.width}>
      <BorderTop height={props.height} width={props.width} />
      <BorderRight height={props.height} width={props.width} />
      <BorderLeft height={props.height} width={props.width} />
      <BorderBottom height={props.height} width={props.width} />
      {props.src && <StyledViewWork src={props.src} alt="View Work" />}
      {props.children}
    </StyledViewWorkButton>
  );
};

interface WorkButtonProps {
  color?: string;
  height: string;
  width: string;
}

interface BorderProps {
  height: string;
  width: string;
}
const StyledViewWorkButton = styled.button<WorkButtonProps>`
  background: transparent;
  font-size: 16px;
  font-family: ${theme.PRIMARY_FONT};
  height: ${props => props.height};
  width: ${props => props.width};
  max-height: 100px;
  max-width: 200px;
  position: relative;
  /* font-family: RegulatorNova; */
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  /* text-align: center; */
  color: ${props => (props.color ? props.color : 'white')};
  /* TEST */
  @media ${device.mobileM} {
  }
  @media ${device.mobileMHeight} {
  }
  @media ${device.mobileLHeight} {
  }
  @media ${device.tabletSHeight} {
    /* width: 250px;
    height: 80px; */
  }
  @media ${device.tablet} {
    max-width: 900px;
  }
  @media ${device.laptop} {
  }
  @media ${device.laptopL} {
  }
  @media ${device.desktop} {
  }
  @media ${device.desktopL} {
  }
`;

const BorderLeft = styled.div<BorderProps>`
  height: ${props => props.height};
  width: ${props => props.width};
  max-height: 100px;
  max-width: 200px;
  top: 0;
  left: 0;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  overflow: hidden;
  @media ${device.tablet} {
    max-width: 900px;
  }
  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 30%;
    background: #2fcbfc;
    width: 2px;
    -webkit-transition-property: bottom;
    transition-property: bottom;
    -webkit-transition-duration: 0.2s;
    transition-duration: 0.2s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    @media ${device.mobileM} {
    }
    @media ${device.mobileMHeight} {
    }
    @media ${device.mobileLHeight} {
    }
    @media ${device.tabletSHeight} {
    }
    @media ${device.mobileL} {
    }
    @media ${device.laptop} {
    }
    @media ${device.laptopL} {
    }
    @media ${device.desktop} {
    }
    @media ${device.desktopL} {
    }
  }
  ${StyledViewWorkButton}:hover & :before {
    top: 0;
    bottom: 0;
  }
  @media ${device.mobileM} {
  }
  @media ${device.mobileMHeight} {
  }
  @media ${device.mobileLHeight} {
  }
  @media ${device.tabletSHeight} {
    /* width: 250px;
    height: 80px; */
  }
  @media ${device.mobileL} {
  }
  @media ${device.laptop} {
  }
  @media ${device.laptopL} {
  }
  @media ${device.desktop} {
  }
  @media ${device.desktopL} {
  }
`;

const BorderRight = styled.div<BorderProps>`
  height: ${props => props.height};
  width: ${props => props.width};
  max-height: 100px;
  max-width: 200px;
  top: 0;
  right: 0;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  overflow: hidden;
  @media ${device.tablet} {
    max-width: 900px;
  }
  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 30%;
    /* left: 0; */
    right: 0;
    bottom: 0;
    background: #2fcbfc;
    width: 2px;
    -webkit-transition-property: top;
    transition-property: top;
    -webkit-transition-duration: 0.2s;
    transition-duration: 0.2s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    @media ${device.mobileM} {
    }
    @media ${device.mobileMHeight} {
    }
    @media ${device.mobileLHeight} {
    }
    @media ${device.tabletSHeight} {
      /* width: 4px; */
    }
    @media ${device.mobileL} {
      max-width: 900px;
    }
    @media ${device.laptop} {
    }
    @media ${device.laptopL} {
    }
    @media ${device.desktop} {
    }
    @media ${device.desktopL} {
    }
  }
  ${StyledViewWorkButton}:hover & :before {
    top: 0;
    bottom: 0;
  }
  @media ${device.mobileM} {
  }
  @media ${device.mobileMHeight} {
  }
  @media ${device.mobileLHeight} {
  }
  @media ${device.tabletSHeight} {
    /* width: 250px;
    height: 80px; */
  }
  @media ${device.mobileL} {
  }
  @media ${device.laptop} {
  }
  @media ${device.laptopL} {
  }
  @media ${device.desktop} {
  }
  @media ${device.desktopL} {
  }
`;

const BorderBottom = styled.div<BorderProps>`
  height: ${props => props.height};
  width: ${props => props.width};
  max-height: 100px;
  max-width: 200px;
  top: 0;
  right: 0;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  overflow: hidden;
  @media ${device.tablet} {
    max-width: 900px;
  }
  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    left: 0%;
    right: 0;
    bottom: 0;
    background: #2fcbfc;
    height: 2px;
    -webkit-transition-property: left;
    transition-property: left;
    -webkit-transition-duration: 0.2s;
    transition-duration: 0.2s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    @media ${device.mobileM} {
    }
    @media ${device.mobileMHeight} {
    }
    @media ${device.mobileLHeight} {
    }
    @media ${device.tabletSHeight} {
      height: 4px;
    }
    @media ${device.mobileL} {
      max-width: 900px;
    }
    @media ${device.laptop} {
    }
    @media ${device.laptopL} {
    }
    @media ${device.desktop} {
    }
    @media ${device.desktopL} {
    }
  }
  ${StyledViewWorkButton}:hover & :before {
    left: 10%;
  }
  @media ${device.mobileM} {
  }
  @media ${device.mobileMHeight} {
  }
  @media ${device.mobileLHeight} {
  }
  @media ${device.tabletSHeight} {
    /* width: 250px;
    height: 80px; */
  }
  @media ${device.mobileL} {
  }
  @media ${device.laptop} {
  }
  @media ${device.laptopL} {
  }
  @media ${device.desktop} {
  }
  @media ${device.desktopL} {
  }
`;

const BorderTop = styled.div<BorderProps>`
  height: ${props => props.height};
  width: ${props => props.width};
  max-height: 100px;
  max-width: 200px;
  top: 0;
  right: 0;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  overflow: hidden;
  @media ${device.tablet} {
    max-width: 900px;
  }
  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0%;
    top: 0;
    background: #2fcbfc;
    height: 2px;
    -webkit-transition-property: right;
    transition-property: right;
    -webkit-transition-duration: 0.2s;
    transition-duration: 0.2s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    @media ${device.mobileM} {
    }
    @media ${device.mobileMHeight} {
    }
    @media ${device.mobileLHeight} {
    }
    @media ${device.tabletSHeight} {
      /* height: 4px; */
    }
    @media ${device.mobileL} {
    }
    @media ${device.laptop} {
    }
    @media ${device.laptopL} {
    }
    @media ${device.desktop} {
    }
    @media ${device.desktopL} {
    }
  }
  ${StyledViewWorkButton}:hover & :before {
    right: 10%;
  }
  @media ${device.mobileM} {
  }
  @media ${device.mobileMHeight} {
  }
  @media ${device.mobileLHeight} {
  }
  @media ${device.tabletSHeight} {
    /* width: 250px;
    height: 80px; */
  }
  @media ${device.mobileL} {
  }
  @media ${device.laptop} {
  }
  @media ${device.laptopL} {
  }
  @media ${device.desktop} {
  }
  @media ${device.desktopL} {
  }
`;

const StyledViewWork = styled.img`
  padding: 5px 10px;
  width: 80%;
`;
