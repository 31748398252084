export const theme = {
  BLACK_COLOR: '#000000',
  WHITE_COLOR: '#ffffff',
  MAIN_DARK_BLUE: '#053148',
  LIGHT_BLUE: '#8cd6f6',
  MID_BLUE: '#398EBB',
  SKY_BLUE: '#3EC7F4',
  FOOTER_GRAY: '#A7A9AC',
  SECONDARY_FONT: 'ObjektivMk2',
  PRIMARY_FONT: 'Noto Sans',
  BACKGROUND_COLOR: '#0B131C',
  GREY_BLUE: '#D3E3EC',
  ELECTRIC_BLUE: '#0099E9',
  LIGHT_GREY: '#EEEEEE',
  REALTOR_RED: '#D6262D',
  IMPRINT_PRIMARY: '#76C7CC', 
  IMPRINT_SECONDARY: '#23729B',
  TAPPING_PRIMARY: '#6B598E',
  TAPPING_SECONDARY: '#A59DC0',
};
