import styled from 'styled-components';
import { theme } from '../../utils/theme';

interface Props {
  size?: number;
  color?: string;
}

export const NewAppTitle = styled.h1<Props>`
  font-family: ${theme.SECONDARY_FONT};
  font-size: 37px;
  font-weight: 700;
  letter-spacing: 0px;
  color: ${props => props.color ? props.color : theme.WHITE_COLOR};
  text-align: left;
`;
